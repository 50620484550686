<template>
    <div>
      <div style="width: 100%">
      <div class="goodsDetail_header_inner">
        <div style="font-size: 18px; width: 80%; margin: 0 auto; color: #515a6e">
          <i class="el-icon-s-home"></i>{{ $t("Home") }} >{{ $t("Series Products") }}
        </div>
      </div>
      <ul class="disQueryUl">
        <li class="title" style="margin-right: 15px;">{{ $t("Series Products") }}:</li>
        <li v-for="(item, index) in TypeList" :key="index" @click="toSeriesPage(item)">
          <div v-if="item.id === ipId" class="hoverCls">{{ item.name }}</div>
          <div v-else class="disli">{{ item.name }}</div>
        </li>
      </ul>
    </div>

    <!-- 功能按钮 -->
    <div class="goodsList" style="margin-top: 20px;margin-bottom: 20px;display: block;">
      <div class="prod_handle_add">
        <p style="color: #218da0;font-size: 16px;margin-right: 30px;">
          {{ $t("Qty") }}:{{ displayImagesInfo.bind_number }}
          {{ $t("Subtotal") }}:{{ displayImagesInfo.bind_total }}
        </p>
        <p class="prod_handle_title">
          <a @click="allAddCarts()"  v-loading="loadding">{{ $t("All Add to Cart") }}</a>
        </p>
      </div>

    </div>
    <!--  -->
      <div class="goodsList" v-show="goodsList.length > 0">
        <div class="goodsList_div" v-for="(item, index) in goodsList" :key="index">
          <div class="goodsList_div_imgdiv">
  
            <div class="goodsList_div_imgdiv_icon">
              <img v-for="(fname,index) in item.iconArr" height="30" :src="fname" :key="index">
            </div>
  
            <div class="goodsList_div_imgdiv_goodsImg">
              <img :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
                   style="width: 350px;" @click="clickImg(item)" />
            </div>
  
          </div>
          <div class="goodsList_div_bottom">
            <div class="prod_title">
              <span style="color: #000000; font-size: 14px; margin-right: 5px">{{ item.item_no }}</span>
              <i class="el-icon-document-copy" v-clipboard:copy="item.item_no" v-clipboard:success="onCopy"
                 v-clipboard:error="onError"></i>
            </div>
            <div class="prod_title">
              <el-tooltip :content="item.item_en" placement="bottom" effect="light">
                <p style="color: #000000; font-size: 15px; margin-right: 5px">
                  {{ (lang === "zh-CN" ? item.item_name : item.item_en) | cutstr }}
                </p>
              </el-tooltip>
  
            </div>
            <div class="prod_title">
              <div style="font-size: 14px; color: #000000;">{{ $t("PURCHASE PRICE") }}:</div>
              <div style="font-size: 14px; color: #d92524; margin-left: 5px;">
                {{ $t("Price symbol") }}{{ item.base_price.toFixed(2) }}
              </div>
              <div style="font-size: 14px; color: #000000; margin-left: 5px">
                {{ $t("RETAIL PRICE") }}:
              </div>
              <div style="font-size: 14px; color: #d92524; margin-left: 5px">
                {{ $t("Price symbol") }}{{ item.sale_price.toFixed(2) }}
              </div>
            </div>
  
            <div class="change_num">
              <el-input-number v-model="item.addNum" @change="handleChange" :step="item.purchase_spec"
                               :min="item.purchase_spec" :max="999">
              </el-input-number>
            </div>
            <div class="prod_handle">
              <div class="prod_handle_add">
                <p class="prod_handle_title">
                  <a @click="addCarts(item)">{{ $t("Add to Cart") }}</a>
                  <a v-if="item.user_cart_info"
                     style="margin-left: 8px">[{{ item.user_cart_info.num }}]</a>
                  <a v-else style="margin-left: 8px">[0]</a>
                  <i class="el-icon-delete" style="margin-left: 8px"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="notFindGoods" v-show="goodsList.length === 0">
        {{ $t("No products of this type found") }}
      </div>
      <Footer @setPages="setPages" ref="footPagesB"></Footer>
    </div>
  </template>
  <script>
  import { GetDisplayGoodsList,GetDisplatInfo,AllAddToCart} from "@/api/goods/series.js";
  
  import common from "@/common/mixins/common.js";
  import { AddToCartByGoods } from "@/api/carts/carts.js";
  import Footer from "@/views/web/allGoods/footer/index.vue";
  import { getIpType } from "@/api/goods/goods.js";
  
  export default {
    mixins: [common],
    components: {
      Footer,
    },
    data() {
      return {
        num: [],
        goodsList: [],
        typeList: [],
        queryData: {
          displayImagesId: "",//陈列照
          page: 1,
          pageSize: 16,
        },
        lang: this.$i18n.locale,
        TypeList: [],//所有系列LIST
        ipId:0, //当前系列ID
        displayImagesInfo:"" ,//当前陈列照信息
        loadding:false,
      };
    },
    watch: {
      "queryData.displayImagesId"() {
        this.$route.query.id=this.queryData.displayImagesId;
        this.getData();
      },
    },
    created() {
      var query=this.$route.query;
      this.ipId = parseInt(query.typeId)
      this.queryData.displayImagesId = parseInt(query.id)
      this.getData();
      this.getTypeData();
      this.getDisplayImagesInfo();//获取陈列照信息
    },
    methods: {
      //复制商品条码
      onCopy: function() {
        this.$message.success("Product barcode copied to clipboard");
      },
      onError: function() {
        this.$message.success("Copy failed");
      },
      //设置活动选项
      selDis(val) {
        this.queryData.typeId=val.id;
        this.queryData.page=1;
        //this.getData()
      },
      //加入购物车
      addCarts(row) {
        if ((row.addNum % row.purchase_spec) != 0) {
          this.$message.error(this.$t("Please change in multiples of specifications"));
          this.$nextTick(() => {
            row.addNum=row.purchase_spec;
          });
        } else {
          AddToCartByGoods(row.item_no, row.addNum, row.purchase_spec).then(() => {
            this.$emit("initCart");
            this.getData();
          });
        }
      },
      //设置翻页
      setPages(page) {
        this.queryData.page=page;
        this.getData();
      },
      //获取列表产品
      getData() {
        let queryData={};
  
        var query=this.$route.query;
        queryData.displayImagesId=query.id;
        queryData.page=this.queryData.page;
        queryData.pageSize=this.queryData.pageSize;
  
        GetDisplayGoodsList(queryData).then((res) => {
          let resData=res.data;
  
          //设置分页
          this.$refs.footPagesB.setPages(
            resData.data.page,
            resData.data.total,
            resData.data.page_size,
          );
  
          this.goodsList=this.filterGoods(resData.data.data_list);
        }).catch(() => {
          this.$message.error("Unable to find product"); //无法找到产品
        });
      },
      //设定一个添加购物车的数据，用来绑定添加购物车的数量
      filterGoods(goodsData) {
        let resData=goodsData;
        for (let i=0; i < resData.length; i++) {
          resData[i].addNum=resData[i].purchase_spec;
          resData[i].iconArr=resData[i].file_type_icon_list.split(",");
        }
        return resData;
      },
  
      //跳转到详情页
      clickImg(row) {
        this.$router.push({
          path: "/web/goodsDetail",
          query: {
            id: row.item_no,
          },
        });
      },

      // 跳转系列页面
      toSeriesPage(row) {
        this.$router.push({
          path: "/web/ipSeries",
          query: {
            id: row.id,
          },
        });
      },

      // 获取系列类型
      getTypeData() {
        getIpType().then((res) => {
          let resDataList=res.data.data;
          for (let i=0; i<resDataList.length; i++) {
            if (resDataList[i].sta === 0){
              resDataList.splice(i,1);
            }
          }
          this.TypeList=this.filterData(resDataList);
        });
      },
      filterData(getData) {
        for (let key in getData) {
          if (this.$i18n.locale !== "zh-CN") {
            getData[key].name=getData[key].name_en;
          }
        }
        return getData;
      },
      handleChange(value) {
        console.log(value)
      },

      // 获取当前陈列照信息
      getDisplayImagesInfo(){
        var displayImagesId = this.queryData.displayImagesId
         // let that = this
        let queryDisplayData={};
        queryDisplayData.displayImagesId = displayImagesId
        GetDisplatInfo(queryDisplayData).then((res) => {
          let resData=res.data;
          // 赋值变量
          setTimeout(() => {
            this.displayImagesInfo = resData.data

          }, 500);



        }).catch(() => {
          this.$message.error("Unable to find product"); //无法找到产品
        });
      },

      // 全部加购
      allAddCarts(){
        var queryData = {}
        queryData.ipId = this.ipId
        queryData.displayImagesId= this.queryData.displayImagesId

        this.loadding =true
        AllAddToCart(queryData).then((res) => {
          if (res.data.error == 0) {
            this.$message.success("ok")
          }
          // 刷新列表
          this.getData()
          this.$emit("initCart");

        }).catch(() => {
          this.$message.error("Unable to find product"); //无法找到产品
        }).finally(()=>{
          this.loadding = false
        })
      }
      
    },
  };
  </script>
  <style lang="scss" scoped>
  .goodsList {
    width: 1496px;
    margin: 0 auto;
    background-color: #f5f8fa;
    display: grid;
    grid-template-columns: 350px 350px 350px 350px;
    grid-row-gap: 32px;
    grid-column-gap: 32px;
  
    &_div {
      // background: yellow;
      border: 1px solid #ccc;
  
      &_imgdiv {
        min-height: 350px;
        position: relative;
  
        //图标
        &_icon {
          position: absolute;
          top: 2px;
          right: 2px;
  
          img {
            border: 1px solid #ccc;
            margin-left: 3px;
          }
        }
  
        //商品图标
        &_goodsImg {
          cursor: pointer;
          text-align: center;
          border-bottom: 1px solid #ccc;
          min-height: 350px;
        }
      }
  
      //文字按钮样式
      &_bottom {
        text-align: center;
        margin-top: 5px;
      }
  
    }
  }
  
  .prod_title {
    width: 350px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .change_num {
    //width: 356px;
    height: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .prod_handle {
    //width: 356px;
    height: 40px;
    background-color: #ddedf0;
    margin-top: 5px;
  
    &_add {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
  
    &_title {
      color: #218da0;
      font-size: 16px;
      border-bottom: 1px solid #218da0;
    }
  }
  
  .notFindGoods {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }
  
  .banner {
    text-align: center;
    padding-top: 20px;
  }

// =================================系列分类样式=====================start=========
  .disQueryUl {
  display: flex;
  /* border: 1px solid #238DA5; */
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  /* height: 30px; */
  line-height: 30px;
}

.disQueryUl .title {
  font-weight: bold;
  font-size: 18px;
}

.disQueryUl li {
  font-size: 14px;
}

.disQueryUl .disli {
  text-align: center;
  color: #515a6e;
  font-size: 18px;
  margin-right: 18px;
  padding: 5px;
}

.disQueryUl .disli:hover {
  color: white;
  background: #238da5;
  cursor: pointer;
}

/* 高亮显示 */
.disQueryUl .hoverCls {
  text-align: center;
  font-size: 20px;
  margin-right: 25px;
  color: white;
  background: #238da5;
  cursor: pointer;
  padding: 5px;
}
// =================================系列分类样式=====================end=========

  </style>
  